<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Trigger Details</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }" >
                        </CDataTable>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" @click="redirect_to(`/trigger/${$route.params.id}/update`)" class="mr-2">Update</CButton>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            items: [],
        }
    },
    computed: {
        fields(){
            return [
                { key: 'key', label: ''},
                { key: 'value', label: ''}
            ]
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('arbitrage/input_proccess_triggers/'
                + '?id=' + this.$route.params.id
                + '&user_info=1'
                + '&input_proccess_info=1')
                .then(res => {
                    res.data.map(val => {
                        switch(val.is_active){
                            case true:
                                val.is_active = 'Active';
                                break;
                            case false:
                                val.is_active = 'Inactive';
                                break;
                        }
                        if(val.updated_at != '0001-01-01T00:00:00Z'){
                            val.updated_at = this.$moment(val.updated_at).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if(val.last_fire_timestamp != '0001-01-01T00:00:00Z'){
                            val.last_fire_timestamp = this.$moment(val.last_fire_timestamp).format('YYYY-MM-DD HH:mm:ss');
                        }
                        val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        this.items.push(
                            { key: 'ID', value: val.id },
                            { key: 'Name', value: val.name },
                            { key: 'User', value: `${val.user_info.first_name} ${val.user_info.last_name}` },
                            { key: 'Processes', value: val.input_proccess_info.name },
                            { key: 'Compare with', value: val.compare_with },
                            { key: 'Compare Type', value: val.compare_type },
                            { key: 'Last Fire Time Stamp', value: val.last_fire_timestamp },
                            { key: 'Last Fire Values', value: val.last_fire_values },
                            { key: 'State', value: val.is_active },
                            { key: 'Created at', value: val.created_at },
                            { key: 'updated at', value: val.updated_at },
                            { key: 'Callback Text', value: val.callback_info_data.callback_text },
                        );
                        if(val.callback_info_data.email_addresses != null){
                            for(const i in val.callback_info_data.phone_numbers){
                                this.items.push(
                                    { key: `E-mail (${parseInt(i)+1})`, value: val.callback_info_data.email_addresses[i] },
                                );
                            }
                        }
                        if(val.callback_info_data.phone_numbers != null){
                            for(const i in val.callback_info_data.phone_numbers){
                                this.items.push(
                                    { key: `Phone Number (${parseInt(i)+1})`, value: val.callback_info_data.phone_numbers[i] },
                                );
                            }
                        }
                        if(val.callback_info_data.telegram_ids != null){
                            for(const i in val.callback_info_data.phone_numbers){
                                this.items.push(
                                    { key: `Telegram ID (${parseInt(i)+1})`, value: val.callback_info_data.telegram_ids[i] },
                                );
                            }
                        }
                    })
                })
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
